export default defineNuxtRouteMiddleware(() => {
  // if (process.client) {
  //   const router = useRouter();

  //   const { publicPages } = useRuntimeConfig().public;
  //   const { isLogin, $state } = useUserStore();
  //   const goToHome = () => router.replace("/home");

  //   if (isLogin()) {
  //     if (publicPages.includes(to.path)) {
  //       return goToHome();
  //     } else if (to.path === "/home/company/pendent" && $state.user && $state.user.type !== 2) {
  //       return goToHome();
  //     }
  //   } else if (!publicPages.includes(to.path)) {
  //     return router.replace("/");
  //   }
  // }
});
